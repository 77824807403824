// i18next-extract-mark-ns-start imprint

import {graphql} from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet';

const Imprint = (props) => {
  const {t} = useTranslation();
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>{t("Imprint")}</title>
      </Helmet>
      <h1 style={{marginBottom: '5rem'}}>
        <Trans>Imprint</Trans>
      </h1>
      <h3>
        <Trans>Entries referred to §5 TMG:</Trans>
      </h3>
      <p>
        Thomas Schmidt <br/>
        Schwarze Str. 5 <br/>
        45326 Essen <br/>
      </p>
      <p>
        <b>E-Mail:</b> info@present-helper.com
      </p>
      <p>
        <b>USt-IdNr:</b> DE342545955
      </p>
      <p>
        <Trans>
            Thomas Schmidt is a participant in the Amazon affiliate program, which was designed to provide a medium for websites
             by means of which fees can be earned through the placement of partner links to Amazon.de / Amazon.com .
        </Trans>
      </p>
      <h4>
        <Trans>Image Sources</Trans>
      </h4>
      <ul>
        <li>
            <a href='https://undraw.co/'>undraw.co</a>
        </li>
        <li>
            <a href='https://pexels.com/'>pexels.com</a>
        </li>
      </ul>
    </Layout>
  );
};

export default Imprint;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "imprint"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
